<template>
  <div v-loading="loading">
    <web-header :active-url="info.classes && info.classes.list_template ? info.classes.list_template : ''" borderBottom></web-header>
    <div class="news-detail">
      <div class="f-wrap">
        <div class="f-wrap-container">
          <div class="f-wrap-container-header">
            <div class="title">{{ info.title }}</div>
            <div class="desc">
              <span v-if="info.send_time">时间：{{ dateTimeFormats("YYYY-MM-DD", info.send_time) }}</span>
              <span v-if="info.author">作者：{{ info.author }}</span>
            </div>
          </div>
          <div class="f-wrap-container-content" v-html="info.content"></div>
          <div class="f-wrap-container-share">
            <div class="web-site-share">
              <div class="share-label">分享到:</div>
              <div class="share-box bdsharebuttonbox">
                <a href="javascript:void(0);" class="share-item icon-wchat" data-cmd="weixin" title="分享到微信"></a>
                <a href="javascript:void(0);" class="share-item icon-sinawb" data-cmd="tsina" title="分享到新浪微博"></a>
                <a href="javascript:void(0);" class="share-item icon-qq" data-cmd="sqq" title="分享到QQ好友"></a>
                <a href="javascript:void(0);" class="share-item icon-qzone1 popup_twi" onclick="return false;" data-cmd="twi" title="分享到Twitter"></a>
                <a href="javascript:void(0);" class="share-item icon-qzone1 icon-qzone2 popup_print" onclick="return false;" data-cmd="print" title="打印"></a>
                <a href="javascript:void(0);" class="share-item icon-smore" data-cmd="more" title="更多分享"></a>
              </div>
            </div>
          </div>
          <div class="f-wrap-container-foot">
            <div class="f-wrap-container-foot-title">相关研究院动态</div>
            <div class="f-wrap-container-foot-list">
              <div class="f-wrap-container-foot-list-item" v-for="(item, index) in relatedList" :key="index">
                <div class="f-wrap-container-foot-list-item-left">
                  <span class="fa fa-circle-thin"></span>
                  <a :href="'/news_detail/'+item.class_id+'/'+item.id" target="_blank">{{ item.title }}</a>
                </div>
                <div class="f-wrap-container-foot-list-item-right">
                  {{ dateTimeFormats("YYYY-MM-DD", item.send_time) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <web-navigation></web-navigation>
    <web-footer></web-footer>
    <web-right-menu class="right-menu"></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import WebNavigation from "@/components/webNavigation";
import { articleDetail, articleList } from "@/api";
export default {
  name: 'NewsDetail',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    WebNavigation
  },
  data () {
    return {
      loading: true,
      // 当前信息
      info: {},
      // 相关联的
      relatedList: [],
    }
  },
  async mounted () {
    await this.loadExternalScript("http://bdimg.share.baidu.com/static/api/js/share.js?v=89860593.js");
    window._bd_share_config = {
      "common": {
        "bdSnsKey":{},
        "bdText":"",
        "bdMini":"2",
        "bdMiniList":false,
        "bdPic":"",
        "bdStyle":"0",
        "bdSize":"24"
      },
      "share":{}
    }
  },
  created() {
    if (this.$route.params.cid && this.isNumber(this.$route.params.cid) && this.$route.params.id && this.isNumber(this.$route.params.id)) {
      this.getInfoData(this.$route.params.cid, this.$route.params.id);
    } else {
      this.$message.warning("数据获取失败，请重试");
      this.$router.back();
    }
  },
  methods: {
    // 获取详情
    async getInfoData(cid, id) {
      this.loading = true;
      let res = await articleDetail(cid, id);
      if (res && res.code == 200) {
        if (res.data && res.data.jump_link) {
          this.routerLink(res.data.jump_link);
        }
        await this.getRelatedData(id, cid);
        this.info = res.data;
        this.setPageSeo(this.info.seo_title, this.info.keywords, this.info.seo_desc);
      }
      this.loading = false;
    },
    // 获取相关内容
    async getRelatedData(msgId, cid) {
      let param = {
        pageSize: 10,
        currentPage: 1,
        orderBy: "send_time,sort,topping",
        sortedBy: "desc",
        search: `id:${msgId}`,
        searchFields: "id:<>"
      };
      let res = await articleList(param, cid);
      if (res && res.code == 200) {
        this.relatedList = res.data.list;
      }
    },
    // 时间格式化
    dateTimeFormats(format, e) {
      return this.$moment(e).format(format);
    }
  }
}
</script>
<style lang="scss">
.news-detail {
  padding-top: 100px;
  padding-bottom: 120px;
  background-color: #f7f7f7;
  .f-wrap {
    background-color: #ffffff;
    padding: 60px 0 70px;
    &-container {
      width: 790px;
      margin: 0 auto;
      &-header {
        position: relative;
        margin-bottom: 40px;
        text-align: center;
        padding-bottom: 27px;
        border-bottom: 1px solid #eeeded;
        &:after {
          content: "";
          width: 35px;
          height: 3px;
          background: #004898;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
        .title {
          margin-bottom: 20px;
          font-family: "微软雅黑" !important;
          font-size: 30px;
        }
        .desc {
          span {
            margin: 0 15px;
            color: #707070;
          }
        }
      }
      &-content {
        text-align: justify;
      }
      &-share {
        margin-top: 70px;
        display: flex;
        justify-content: right;
      }
      &-foot {
        border: 1px solid #e5e5e5;
        margin-top: 32px;
        &-title {
          font-size: 20px;
          font-weight: bolder;
          margin: 35px 0 0 60px;
        }
        &-list {
          margin: 0 60px 35px 60px;
          &-item {
            font-size: 14px;
            padding: 11px 0;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #e5e5e5;
            &:last-child {
              border: none;
            }
            &-left {
              flex: 1;
              white-space: nowrap;      /* 确保文本在一行内显示 */
              overflow: hidden;         /* 超出容器的文本隐藏 */
              text-overflow: ellipsis;  /* 超出部分显示省略号 */
              .fa-circle-thin {
                vertical-align: middle;
                margin-right: 5px;
                font-size: 0;
                width: 6px;
                height: 6px;
                display: inline-block;
                border-radius: 50%;
                border: 2px solid #004898;
              }
            }
            &-right {
              min-width: 120px;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .news-detail {
    padding-top: 50px;
    padding-bottom: 60px;
    .f-wrap {
      &-container {
        width: calc(100% - 60px);
        padding: 0 30px;
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .news-detail {
    padding-top: 25px;
    padding-bottom: 30px;
    .f-wrap {
      &-container {
        width: calc(100% - 30px);
        padding: 0 15px;
        &-header {
          .title {
            font-size: 18px;
          }
        }
        &-share {
          margin-top: 35px;
          justify-content: left;
        }
        &-foot {
          &-title {
            font-size: 18px;
            margin: 15px 0 0 15px;
          }
          &-list {
            margin: 0 15px 15px 15px;
            &-item {
              &-right {
                min-width: 90px;
              }
            }
          }
        }
      }
    }
  }
}
</style>